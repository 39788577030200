import React from "react"
import { Dispatch } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { hostProperty, updateProperty } from "../helper/firebase/transaction/listing.trx"
import { useInputChange } from "../custom-hook/useForm"

const HostProperty = () => {
  const [input, handleInputChange] = useInputChange()

  const dispatch = useDispatch()
  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const propertyStatus = useSelector((state: any) => state.property.propertyStatus)

  const customHostHook = hostProperty(dispatch as Dispatch)
  const customUpdateHook = updateProperty(dispatch as Dispatch)

  let isClicked = false
  const highlights: { [key: string]: any } = {}

  const handleClick = (event: any) => {
    const name = event.currentTarget.name

    isClicked = highlights[name] === undefined ? true : !highlights[name]

    if (isClicked) {
      event.target.style.border = "2px solid #000"
      highlights[name] = isClicked

      console.log(event?.currentTarget.name)
    } else {
      event.target.style.border = "none"
      const name = event.currentTarget.name
      highlights[name] = isClicked
    }

    console.log(highlights)
  }

  return (
    <div>
      <button onClick={() => customHostHook(userStatus, propertyStatus)}>Host Property</button>

      <button name="peaceful" onClick={handleClick} onChange={handleInputChange}>
        <span>Peaceful</span>
      </button>
      <button name="unique" onClick={handleClick} onChange={handleInputChange}>
        <span>unique</span>
      </button>
      <button onClick={() => customUpdateHook(userStatus, propertyStatus, highlights, input)}>next</button>
    </div>
  )
}

export default HostProperty
