import { Dispatch } from "@reduxjs/toolkit"
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore"
import { db } from ".."
import { property } from "../../duck/property/index.td"
import {
  hostPropertySuccess,
  hostPropertyFailed,
  updatePropertySuccess,
  updatedPropertyFailed,
  propertyFetch
} from "../../duck/property"
import { errorFactory, userLogin } from "../../duck/auth/index.td"
import { FirebaseError } from "firebase/app"

const transaction = async (myCollection: string, propertyId: string) => {
  const trx = query(collection(db, myCollection), where("propertyId", "==", propertyId))
  const querySnapshot = await getDocs(trx)
  let propertyData = {}
  querySnapshot.forEach(doc => {
    propertyData = { ...doc.data(), id: doc.id }
  })
  return propertyData
}

const newProperty = async (email: string) => {
  const newPropertyRef = doc(collection(db, "propertyList"))

  await setDoc(newPropertyRef, {
    hostEmail: email,
    propertyTypeGroup: "propertyTypeGroup",
    propertyType: "propertyType",
    privacyType: "privacyType",
    address: {
      street: "street",
      apartment: "apartment",
      city: "city",
      state: "state",
      zipCode: "zipCode",
      country: "country",
      showSpecificLocation: false
    },
    floorPlan: {
      guests: "guests",
      Beds: "Beds",
      Bedrooms: "Bedrooms",
      Bathrooms: "Bathrooms"
    },
    amenities: {
      pool: false,
      hotTub: false,
      patio: false,
      bbqGrill: false,
      firePit: false,
      poolTable: false,
      indoorFireplace: false,
      outdoorDiningArea: false,
      exerciseEquipment: false
    },
    guestFavorites: {
      wifi: false,
      tv: false,
      kitchen: false,
      washer: false,
      freeParking: false,
      paidParking: false,
      airConditioning: false,
      dedicatedWorkspace: false,
      outdoorShower: false
    },
    safetyItems: {
      smokeAlarm: false,
      firsAidKit: false,
      carbonMonoxideAlarm: false,
      fireExtinguisher: false
    },
    photos: [],
    title: "title",
    highlights: {
      peaceful: false,
      unique: false,
      familyFriendly: false,
      stylish: false,
      central: false,
      spacious: false
    },
    description: "description",
    price: "price",
    level: 1,
    isComplete: false,
    propertyId: newPropertyRef.id
  })
}

export const hostProperty = (dispatch: Dispatch) => async (userStatus: userLogin, propertyStatus: property) => {
  let code: errorFactory = {}

  const {
    user: { email }
  } = userStatus

  try {
    await newProperty(email)

    const trx = query(collection(db, "propertyList"), where("hostEmail", "==", email))
    const querySnapshot = await getDocs(trx)
    let hostProperty = {}
    querySnapshot.forEach(doc => {
      hostProperty = { ...doc.data(), id: doc.id }
    })
    dispatch(hostPropertySuccess({ ...propertyStatus, property: hostProperty }))
  } catch (error: errorFactory) {
    code = { code: error?.code }
    if (error instanceof FirebaseError) dispatch(hostPropertyFailed(code))
  }
}

export const updateProperty =
  (dispatch: Dispatch) =>
  async (userStatus: userLogin, propertyStatus: property, highlights: any, input: Record<string, unknown>) => {
    let code: errorFactory = {}

    const {
      property: { propertyId }
    } = propertyStatus

    try {
      const propertyData: any = await transaction("propertyList", propertyId)
      const propertyRef = doc(db, "propertyList", propertyData.id)

      switch (propertyData.level) {
        case 1:
          await setDoc(
            propertyRef,
            {
              propertyTypeGroup: input.propertyTypeGroup,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 2:
          await setDoc(
            propertyRef,
            {
              propertyType: input.propertyType,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 3:
          await setDoc(
            propertyRef,
            {
              privacyType: input.privacyType,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 4:
          await setDoc(
            propertyRef,
            {
              address: input.address,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 5:
          await setDoc(
            propertyRef,
            {
              floorPlan: input.floorPlan,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 6:
          await setDoc(
            propertyRef,
            {
              offers: input.offers,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 7:
          await setDoc(
            propertyRef,
            {
              photos: input.photos,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 8:
          await setDoc(
            propertyRef,
            {
              title: input.title,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 9:
          await setDoc(
            propertyRef,
            {
              highlights: highlights,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 10:
          await setDoc(
            propertyRef,
            {
              description: input.description,
              level: propertyData.level + 1
            },
            { merge: true }
          )
          break
        case 11:
          await setDoc(
            propertyRef,
            {
              price: input.price,
              isComplete: true
            },
            { merge: true }
          )
          break

        default:
          break
      }

      const updatedPropertyData: any = await transaction("propertyList", propertyId)
      dispatch(updatePropertySuccess({ ...propertyStatus, property: updatedPropertyData }))
    } catch (error: errorFactory) {
      code = { code: error?.code }
      if (error instanceof FirebaseError) dispatch(updatedPropertyFailed(code))
    }
  }
